<template>
  <div class="seller-term-content mb-5 mx-auto">
    <h5 class="seller-term-header">AutoFlip Seller Agreement</h5>
    <p>
      By using the AutoFlip Sales Platform ("Platform") operated by IDOM
      Innovations Pty Ltd ABN 87 638 825 751 trading as AutoFlip of Level 2, 199
      Toorak Road, South Yarra, Vic ("AutoFlip" "us") you agree to be bound by
      the terms of this Seller Agreement. If you do not agree to be bound by the
      terms of this Agreement you must not use our services or the Platform.
    </p>
    <p class="bold">Fees and Service</p>
    <p>
      You agree to appoint AutoFlip to find a buyer for your vehicle that you
      have entered onto or will enter onto the Platform ("Vehicle") pursuant to
      the terms of this Agreement.
    </p>
    <p>
      If you sell your Vehicle the seller fees payable by you for the use of the
      AutoFlip Platform and sales service are based on the amount that you sell
      the Vehicle for:
    </p>
    <p v-for="key in Object.keys(successFees)" :key="key">
      {{ `${successFees[key]} - ${key}` }}
    </p>
    <p>If you do not sell your Vehicle you will not pay any seller fees.</p>
    <p>
      You acknowledge that AutoFlip also receives a fee from the buyer of the
      Vehicle.
    </p>
    <p class="bold">The Sales Process</p>
    <p>
      You agree that the reserve sale price for the Vehicle will be set by you
      through our Platform and understand that upon achievement of the reserve
      by AutoFlip you agree to sell vehicle to the highest bidder without any
      further communication with you.
    </p>
    <p>
      If the sale price for the Vehicle does not meet the reserve, a
      representative from AutoFlip will negotiate with the prospective buyers of
      the vehicle to achieve the highest offer possible which will be referred
      to you for consideration as to whether you want to proceed with the sale.
    </p>
    <p>
      Once AutoFlip agrees a sale on your behalf with a buyer of the Vehicle you
      are bound to proceed with the sale process and if you do not proceed you
      may be required to compensate AutoFlip for any loss that it suffers as a
      result of you not proceeding with the sale.
    </p>
    <p>
      You will transfer possession of the Vehicle at the time and in the manner
      agreed with the purchaser of the Vehicle once you are satisfied that the
      purchaser has arranged payment.
    </p>
    <p class="bold">You are appointing us to find a Buyer for the vehicle.</p>
    <p>You warrant that:</p>
    <ol class="letter-parenthesis-list">
      <li>
        You are the owner of the Vehicle or are otherwise authorised to sell the
        Vehicle on behalf of the owner.
      </li>
      <li>
        The Vehicle will be free of any encumbrances at the time of transfer
        other than those encumbrances that have been notified by you.
      </li>
      <li>
        You will not advertise the Vehicle for sale by other means during the
        time that AutoFlip is selling the vehicle on your behalf as this could
        have an adverse effect on the sales process. If the Vehicle is
        advertised for sale prior to engaging with AutoFlip you agree to suspend
        these listings whilst the Vehicle is being offered for sale by AutoFlip.
      </li>
      <li>
        You have described the Vehicle condition accurately through the Platform
        or to us and have declared any known faults but not limited to: paint
        and panel condition including any minor or major dents and paint fade,
        tyre tread condition, warning lights on the dashboard, registration
        expiry date, general interior condition, any mechanical and engine
        issues including oil leaks and well as service history.
      </li>
    </ol>
    <p>
      While AutoFlip will use all reasonable efforts to find a buyer for the
      Vehicle at the highest price, notwithstanding any representation by or
      communication from an employee or representative of AutoFlip, on any
      website or in promotional material, you acknowledge and agree that
      AutoFlip makes no warranty or representation that it will be able to find
      a buyer for the Vehicle or attain a certain price for the Vehicle and
      disclaims and excludes all liability to you (other than liability to you
      that cannot be excluded at law) in respect of AutoFlip being appointed by
      you to find a Buyer for the Vehicle.
    </p>
    <p>
      AutoFlip takes your privacy seriously and any information provided through
      your use of the Website and/or Services are subject to AutoFlip's Privacy
      Policy, which is available at
      <a href="https://www.sell.autoflip.com.au" target="_blank"
        >www.sell.autoflip.com.au</a
      >.
    </p>
    <p>
      The terms set out above and the terms at
      <a href="https://www.sell.autoflip.com.au" target="_blank"
        >www.sell.autoflip.com.au</a
      >
      ("Website Terms") represent the entire agreement between the parties. To
      the extent of any inconsistency between the terms of this agreement and
      the Website Terms, these terms will prevail.
    </p>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      successFees: {
        $165: "Upto $4,999",
        $187: "$5,000 to $9,999",
        $275: "$10,000 to $19,999",
        $297: "$20,000 to $29,999",
        $385: "$30,000 to $39,999",
        $440: "$40,000 to $49,999",
        $550: "$50,000 to $59,999",
        $638: "$60,000 to $69,999",
        $660: "$70,000 plus",
      },
    };
  },
};
</script>

<style lang="scss">
.seller-term-content {
  color: #404040;
  max-width: 394px;
  padding: 35px 20px 63px;
  margin-top: 30px;
  background-color: #ffffff;
  border-radius: 20px;

  @media screen and (min-width: 768px) {
    max-width: 1080px;
    min-height: calc(100vh - 273px);
    padding: 45px 50px 63px;
  }

  .seller-term-header {
    text-align: center !important;
  }

  h5 {
    margin-bottom: 12px;
    font-size: 18px;

    @media screen and (min-width: 768px) {
      font-size: 20px !important;
    }
  }

  p,
  li {
    font-family: Avenir Next LT W05 Thin;
    font-weight: 600;
    margin-bottom: 0;
    padding: 5px 0;
    font-size: 14px;

    @media screen and (min-width: 768px) {
      font-size: 16px !important;
    }
  }

  .bold {
    font-family: Avenir Next LT W05 Demi;
  }

  p.bold {
    font-size: 16px;
  }

  // ======== Parenthesis lists
  ol.letter-parenthesis-list {
    padding-left: 25px;
  }

  ol.letter-parenthesis-list,
  ol.roman-num-parenthesis-list,
  ol.letter-upper-parenthesis-list {
    counter-reset: list;
    margin: 0;
  }
  ol.roman-num-parenthesis-list,
  ol.letter-upper-parenthesis-list {
    margin-top: 10px;
  }

  ol.letter-parenthesis-list > li,
  ol.roman-num-parenthesis-list > li,
  ol.letter-upper-parenthesis-list > li {
    list-style: none;
    position: relative;
  }
  ol.letter-parenthesis-list > li,
  ol.roman-num-parenthesis-list > li {
    padding-bottom: 10px;
  }

  ol.letter-parenthesis-list > li:before,
  ol.roman-num-parenthesis-list > li:before,
  ol.letter-upper-parenthesis-list > li:before {
    counter-increment: list;
    position: absolute;
    left: -1.5em;
  }
  ol.letter-parenthesis-list > li:before {
    content: "(" counter(list, lower-alpha) ") ";
  }
}
</style>
